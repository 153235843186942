<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <div>
              <h2>{{ data.title }}</h2>
            </div>
          </div>
        </div>
        <div class="col-xl-9 mb-3">
          <div class="plyrVideo-wrap">
            <!-- <vue-plyr @player="setPlayer">
              <video
                ref="videoPlayer"
                :src="video_url"
                controls
                crossorigin
                playsinline
                :data-poster="data.image"
                autoplay
              >
                <source
                  size="1080"
                  :src="video_url"
                  type="application/dash+xml"
                />
              </video>
            </vue-plyr> -->
            <DashVideo ref="videoPlayer" :src="video_url" :isPrivate="false" crossorigin />
            <!-- <Player ref="videoPlayer" :src="video_url" /> -->
            <!-- <TestPlayer ref="videoPlayer" :src="video_url" crossorigin />
            <VideoJs ref="videoPlayer" :src="video_url" /> -->
            <!-- <VideoJs ref="videoPlayer" :src="video_url" /> -->
            <!-- <VideoPlayer ref="videoPlayer" :src="video_url" /> -->
          </div>
        </div>
        <div class="col-xl-3 mb-3">
          <div class="Courses-shopLink">
            <div class="coursetext-link text-center">
              <div class="course-texttop">
                <p class="mb-4">{{ data.title }}</p>
              </div>

              <template v-if="data.is_offer">
                <h3 class="price d-block">
                  <del> {{ data.display_price }} </del>
                  {{ data.display_offer_price }}
                </h3>
              </template>
              <template v-else>
                <h3 v-if="data.price == 0" class="price d-block brand-color fw-semibold">
                  Free
                </h3>
                <h3 v-else class="price d-block brand-color fw-semibold">
                  {{ data.display_price }}
                </h3>
              </template>

              <template v-if="!reqItem.purchase">
                <template v-if="data.price !=0">
                  <ActionBuy :itemData="reqItem" />
                </template>
                <ActionCart :itemData="reqItem" />
                <ActionWishlist :itemData="reqItem" />
              </template>
            </div>
            <div class="course-includedata">
              <h3 class="mb-3">This Course Includes</h3>
              <ul>
                <li>{{ data.total_hours }} hours</li>
                <li>{{ data.lessons }} Lessons</li>
                <li>{{ data.subscription }} Days Subscription</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import store from "@/store";
import ActionBuy from "@/components/ActionBuy";
import ActionCart from "@/components/ActionCart";
import ActionWishlist from "@/components/ActionWishlist";
import DashVideo from "../../../components/DashVideo.vue";

export default {
  name: "buycourse",
  components: {
    ActionBuy,
    ActionCart,
    ActionWishlist,
    DashVideo,
  },
  props: {
    courseData: {
      required: true,
      type: [Array, Object],
    },
    userCourseStatus: {
      required: true,
      type: [Array, Object],
    },
    courseVideo: {
      required: true,
    },
  },
  data() {
    return {
      isAuthenticated: store.getters.authenticated,
      courseId: this.courseData,
      data: this.courseData,
      video_url: this.courseVideo,
      player: {},

      reqItem: {
        user_id: store.getters.user.id,
        item_id: this.$route.params.id,
        item_type: "course",
        quantity: 1,
        purchase: this.userCourseStatus.p_status,
      },
    };
  },
  mounted() {
    let app = this;
    app.$root.$on("authentication-status", ($event) => {
      app.isAuthenticated = $event;
    });
    // const video = this.$refs.videoPlayer;
    // this.player = this.$refs.videoPlayer.load(this.courseVideo);

    // video.addEventListener("contextmenu", (event) => {
    //   console.log("User right-clicked on the video element");
    //   // Check if the target of the context menu event is the video element
    //   if (event.target === video) {
    //     // Handle the event (e.g., track it using an analytics service)
    //     console.log("User right-clicked on the video element");
    //   }
    // });
  },
  watch: {
    courseData(value) {
      let app = this;
      app.data = value;
      app.courseId = value.course_id;
      app.reqItem.item_id = app.$route.params.id;
    },
    userCourseStatus(value) {
      let app = this;
      app.reqItem.purchase = value.p_status;
    },
    courseVideo(value) {
      let app = this;
      app.video_url = value;
      // this.player.source({
      //   type: "application/dash+xml",
      //   sources: [
      //     {
      //       src: app.video_url,
      //       type: "application/dash+xml",
      //       size: 720,
      //     },
      //   ],
      // });
    },
  },
  methods: {
    setPlayer(player) {
      this.player = player;
    },
  },
};
</script>
